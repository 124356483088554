import { loadOrReloadSdk as loadOrReloadFacebookSdk } from "./facebook-sdk";
import { htmlToElement, closest } from "./util";
import { subscribe, unsubscribe } from "./observer";
import { tabChange } from "./observer-subjects";

/**
 * Lazy-load video's after the user has chosen to play it.
 */

const PLAYING_CLASS = "is-playing-video";

const addCloseButton = (el) => {
  const button = htmlToElement(`
    <button data-type="video-close" data-handler="lazyVideoEmbedClose" aria-label="Stop and close video">
      <svg role="presentation" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <g fill="none" fill-rule="evenodd" stroke-linecap="square" stroke-width="2">
          <path d="M13.607 2.54L2.335 13.81M13.834 14.098L2.049 2.313"/>
        </g>
      </svg>
    </button>
  `);
  el.appendChild(button);
};

const closeVideo = (button, keepButton) => {
  const videoContainer = button.parentNode.querySelector(".js-video-container");
  videoContainer.parentNode.removeChild(videoContainer);
  closest(button, `.${PLAYING_CLASS}`).classList.remove(PLAYING_CLASS);

  // If there is no close button, prevent removing the play button.
  if (!keepButton) {
    button.parentNode.removeChild(button);
  }
};

const getVideoEmbed = ({ type, id }) => {
  switch (type) {
    case "youtube":
      return `
        <iframe src="https://www.youtube-nocookie.com/embed/${id}?color=white&rel=0&autoplay=1&modestbranding=1&showinfo=0" width="1280" height="720" frameborder="0" allowfullscreen></iframe>
      `;
    case "vimeo":
      return `
        <iframe src="https://player.vimeo.com/video/${id}?autoplay=1&color=01cbe1&title=0&byline=0&portrait=0" width="1280" height="720" frameborder="0" allowfullscreen></iframe>
      `;
    case "facebook":
      return `
        <div class="fb-video-wrapper">
          <div class="fb-video" data-href="https://www.facebook.com/facebook/videos/${id}/" data-width="552" data-show-text="false" data-autoplay="true" data-allowfullscreen="true"></div>
        </div>
      `;
    default:
      return ``;
  }
};

export const close = (el, e) => {
  e.preventDefault();
  closeVideo(el);
};

const embedVideo = (el, toggleGroupId) => {
  const html = el.getAttribute("data-video-html");
  const type = el.getAttribute("data-video-type");
  const id = el.getAttribute("data-video-id");
  if (!html && (!type || !id)) {
    return;
  }

  const embed = html || getVideoEmbed({ type, id });
  const container = htmlToElement(`
    <div class="video-embed js-video-container">${embed}</div>
  `);

  const embedToElement = document.querySelector(
    `#${el.getAttribute("data-video-embed-element")}`,
  );
  const classToElement = document.querySelector(
    `#${el.getAttribute("data-video-class-element")}`,
  );

  if (embedToElement) {
    addCloseButton(embedToElement);
    embedToElement.appendChild(container);
    classToElement.classList.add(PLAYING_CLASS);
  } else {
    el.parentNode.classList.add(PLAYING_CLASS);
    el.parentNode.appendChild(container);
  }

  if (type === "facebook") {
    loadOrReloadFacebookSdk();
  }

  // When embedded video is inside a toggle group item.
  if (toggleGroupId) {
    const handleTabClose = (payloadId) => {
      if (payloadId === toggleGroupId) {
        unsubscribe("toggle-group", handleTabClose);
      }
    };

    subscribe("toggle-group", handleTabClose);
  }

  // Video in Info-tabs.
  // Listens to change in tabs.
  const onTabChange = () => {
    closeVideo(el, true);
    unsubscribe(tabChange, onTabChange);
  };
  subscribe(tabChange, onTabChange);
};

export const play = (el, e) => {
  e.preventDefault();
  embedVideo(el, el.getAttribute("data-id"));
};
